import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';

import { LoginResponse } from '../../types/api-types';
import { storeTokens } from '../../utils/auth-helpers';
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '../../utils/constants';
import { loadFromLocalStorage } from '../../utils/local-storage-helpers';
import { handleLogout } from '../../utils/login-helpers';
import { baseUrl } from '../../utils/queries-helpers';

// Basic RTK query function to make API calls
export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl(),
    prepareHeaders: (headers) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
        const token = loadFromLocalStorage(AUTH_TOKEN_KEY);
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }

        // ---- If Needed to test on multi env ----
        if (process.env.REACT_APP_MULTIENV) {
            headers.set('X-Test-Env', process.env.REACT_APP_MULTIENV);
        }

        return headers;
    },
});

const mutex = new Mutex();

// RTK Query function with Token refresh in case of 401
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                // try to get a new token
                const refreshResult = await baseQuery({
                    body: { refresh_token: loadFromLocalStorage(AUTH_REFRESH_TOKEN_KEY) },
                    method: 'POST',
                    url: 'token/refresh',
                }, api, extraOptions);

                if (refreshResult.data && !refreshResult.error) {
                    // store the new token
                    storeTokens(refreshResult.data as LoginResponse);
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    // We logout of the app
                    handleLogout(api.dispatch);
                }
            } finally {
            // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};
